import $ from 'jquery';

const FORM_VALIDATION_URL = '/api/shop/{_locale}/validate-form/{_form_name}';
// check FormValidationController for supported forms
const forms = [
    'customer_login_form',
    'customer_registration_form',
    'checkout_address_form',
    'request_form',
    'customer_edit_form',
    'change_password_form',
    'reset_password_form',
    'forgot_password_form',
    'customer_account_recovery_form'
];

document.addEventListener('DOMContentLoaded', () => {

    forms.map(name => {
        const formElements = document.getElementsByName(name);

        if (!formElements.length > 0) {
            return;
        }

        Array.from(formElements).map(form => {

            form.addEventListener('submit', e => {
                e.preventDefault();
                e.stopPropagation();


                const name = form.getAttribute('name');

                if (!name) {
                    form.submit();
                    return;
                }

                // clear all the existing error messages when trying to submit the form
                form.querySelectorAll('.form-error').forEach(el => el.remove());
                form.querySelectorAll('.form-group').forEach(el => el.classList.remove('error'));

                Array.from(form.elements).forEach(el => {
                    if (el.type === 'submit') {
                        return;
                    }

                    // if there is an error message and the user tries to type again, remove the error message
                    el.addEventListener('input', () => {
                        el.closest('.form-group')?.classList.remove('error');
                        el.closest('.form-group')?.querySelector('.form-error')?.remove();
                    })

                });

                form.classList.remove('form-validate'); // this removes the invalid message when trying to submit the form again.

                const validateUrl = FORM_VALIDATION_URL.replace('{_form_name}', name).replace('{_locale}', document.documentElement.lang || 'de');


                form.querySelector('button[type="submit"]')?.setAttribute('disabled', 'disabled');

                const formData = new FormData(form);

                fetch(validateUrl, {
                    method: 'POST',
                    body: formData,
                })
                    .then(res => res.json())
                    .then(({ success, token, errors }) => {
                        const tokenInput = form.querySelector(`#${name}__token`);
                        if (tokenInput) {
                            tokenInput.setAttribute('value', token);
                            tokenInput.value = token;
                        }

                        if (success) {
                            form.submit();
                            return;
                        }
                        Object.keys(errors).map(key => {
                            const fieldErrors = errors[key];
                            const input = form.querySelector(`[name="${name}[${key}]"]`);

                            if (fieldErrors.length === 0 || !input) {
                                return;
                            }

                            const group = input.closest('.form-group');
                            if (group) {
                                group.classList.add('error');
                            }

                            const ul = document.createElement('ul');
                            ul.classList.add('form-error');
                            ul.style.display = 'block';

                            fieldErrors.map(e => {
                                const li = document.createElement('li');
                                li.innerText = e;
                                ul.appendChild(li);
                            });

                            input.parentElement.appendChild(ul);

                        });

                        // if there are errors, scroll to the first error message
                        const errorElement = form.querySelector('.form-error');
                        if (errorElement) {
                            errorElement.scrollIntoView({ behavior: 'smooth' });
                        }
                    })
                    .catch(err => {
                        console.error(err);
                        if (reload) {
                            form.submit();
                        }

                        form.querySelector('button[type="submit"]')?.removeAttribute('disabled');
                        throw err;
                    }).finally(() => {

                        form.querySelector('button[type="submit"]')?.removeAttribute('disabled');
                    });

            });
        })

    })

});
